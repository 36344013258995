var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "p",
      [
        _vm._v(" " + _vm._s(_vm.healthCheckText) + " "),
        _c("font-awesome-icon", {
          staticClass: "fa-fw",
          class: _vm.classObject,
          attrs: { icon: ["fal", _vm.icon], spin: _vm.iconSpinning },
        }),
      ],
      1
    ),
    _c("p", { staticClass: "description" }, [_vm._v(_vm._s(_vm.description))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }