var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "client" }, [
    _c(
      "div",
      { staticClass: "left", class: { expanded: _vm.managementTableOpen } },
      [
        _vm._m(0),
        _vm.isLoading
          ? _c("loading-overlay")
          : _c(
              "div",
              { staticClass: "client-holder" },
              [
                _c("div", { staticClass: "client-holder-header" }, [
                  _c("div", { staticClass: "search-wrapper" }, [
                    _c("label", { attrs: { for: "search" } }, [
                      _vm._v("Please select a client from the list below:"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.clientSearchText,
                          expression: "clientSearchText",
                        },
                      ],
                      ref: "searchInput",
                      attrs: {
                        type: "text",
                        placeholder: "Search...",
                        id: "search",
                      },
                      domProps: { value: _vm.clientSearchText },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleKeyup.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.clientSearchText = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: !_vm.managementData.length
                            ? "Loading scheme setup status"
                            : "Toggle scheme setup status",
                          expression:
                            "!managementData.length ? 'Loading scheme setup status' : 'Toggle scheme setup status'",
                        },
                      ],
                      staticClass: "open-management",
                    },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade", mode: "out-in" } },
                        [
                          !_vm.managementData.length
                            ? _c("font-awesome-icon", {
                                staticClass: "loader show fa-fw",
                                attrs: { icon: ["fal", "spinner"], spin: "" },
                              })
                            : _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: { click: _vm.toggleManagementTable },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "icon",
                                    attrs: { icon: ["fad", "info-circle"] },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.clientsLength === 0
                  ? _c("p", { staticClass: "mt-5" }, [
                      _vm._v("You don't have any client access set up"),
                    ])
                  : _vm.filteredClientsLength === 0
                  ? _c("p", { staticClass: "mt-5" }, [
                      _vm._v("No Clients match the search"),
                    ])
                  : _c("ClientLoginContainer", {
                      attrs: {
                        "setup-status": _vm.filteredManagementData,
                        filteredClients: _vm.filteredClients,
                        setupStatusOpen: _vm.managementTableOpen,
                      },
                      on: { "select-client-by-id": _vm.selectClientById },
                    }),
              ],
              1
            ),
        _vm._m(1),
      ],
      1
    ),
    _c("div", { staticClass: "right" }, [_c("parallelograms"), _vm._m(2)], 1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("h1", [_vm._v("LCP Monitor Pro")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v(" If your client isn't in the list go to "),
      _c(
        "a",
        {
          staticClass: "link-item",
          attrs: { href: "https://authorise.lcp.uk.com/", target: "_blank" },
        },
        [_vm._v("LCP Authorise")]
      ),
      _vm._v(" to set them up. "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inner-text" }, [
      _c("div", { staticClass: "heading" }, [_vm._v("One tool,")]),
      _c("div", { staticClass: "heading" }, [
        _vm._v("All of your investment monitoring needs"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }