var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.loggedIn
        ? [
            !_vm.isDashboardDisplay ? _c("router-view") : _vm._e(),
            _vm.isDashboardDisplay
              ? _c("div", { staticClass: "homepage" }, [
                  _c(
                    "div",
                    { staticClass: "left-container" },
                    [_c("NavBar")],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right-container" },
                    [
                      _c("TopBar", {
                        attrs: { currentRouteName: _vm.currentRouteName },
                      }),
                      _c(
                        "div",
                        { staticClass: "content-area" },
                        [_c("router-view")],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        : [_c("login")],
      _c("network-error"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }