var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.breadcrumb.length > 0 ? _vm.breadcrumb.at(-1).name : "",
          expression: "breadcrumb.length > 0 ? breadcrumb.at(-1).name : ''",
        },
      ],
      staticClass: "breadcrumb",
      attrs: { "aria-label": "Breadcrumb" },
    },
    [
      _c(
        "ol",
        _vm._l(_vm.breadcrumb, function (item, index) {
          return _c(
            "li",
            {
              key: item.id,
              attrs: {
                "aria-current":
                  index === _vm.breadcrumb.length - 1 ? "page" : null,
              },
            },
            [_vm._v(" " + _vm._s(item.name) + " ")]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }