import * as Msal from 'msal';

export default class AuthenticationService {
    config: Msal.Configuration;

    scopes: Msal.AuthenticationParameters;

    msalObj: Msal.UserAgentApplication;

    constructor (
        config: Msal.Configuration,
        scopes: Msal.AuthenticationParameters
    ) {
        this.config = config;
        this.scopes = scopes;
        this.msalObj = new Msal.UserAgentApplication(config);
    }

    async login (): Promise<void> {
        return this.msalObj
            .loginPopup(this.scopes)
            .then((_loginResponse) => {
                // TODO
            })
            .catch((error) => {
                // TODO: handle error
            });
    }

    async getToken (): Promise<void | Msal.AuthResponse> {
        return this.msalObj
            .acquireTokenSilent(this.scopes)
            .then((tokenResponse) => tokenResponse)
            .catch(() => {
                this.msalObj
                    .acquireTokenPopup(this.scopes)
                    .then((tokenResponse) => tokenResponse)
                    .catch((er) => {
                        // TODO: handler error
                    });
            });
    }

    logout (): void {
        this.msalObj.logout();
    }
}
