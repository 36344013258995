var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "client-login-container" },
    [
      _c(
        "div",
        {
          staticClass: "wrapper wrapper--header",
          class: { "two-column": _vm.setupStatusOpen },
        },
        [
          _c("div", { staticClass: "column" }),
          _vm.areArraysIndexedEqual
            ? _c("div", { staticClass: "column management" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.setupStatusOpen,
                        expression: "setupStatusOpen",
                      },
                    ],
                    staticClass: "grid",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "cell" },
                      [
                        _vm._v(" Last pending update  "),
                        _c("font-awesome-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value:
                                "Clients with a calibration point added after the last live update will be highlighted.",
                              expression:
                                "'Clients with a calibration point added after the last live update will be highlighted.'",
                            },
                          ],
                          attrs: { icon: ["fad", "info-circle"] },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "cell" },
                      [
                        _vm._v(" Last live update  "),
                        _c("font-awesome-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value:
                                "Live updates older than 3 months will be shown in red",
                              expression:
                                "'Live updates older than 3 months will be shown in red'",
                            },
                          ],
                          attrs: { icon: ["fad", "info-circle"] },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "cell" }, [
                      _vm._v("Days since live update"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._l(_vm.filteredClients, function (client, index) {
        return _c(
          "div",
          {
            key: client.clientEntityID,
            staticClass: "wrapper",
            class: { "two-column": _vm.setupStatusOpen },
          },
          [
            _c(
              "button",
              {
                staticClass: "btn column",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.handleClick(client.clientEntityID)
                  },
                },
              },
              [
                _vm._v(" " + _vm._s(client.clientEntityNameDisplayName) + " "),
                _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: ["fad", "user"] },
                }),
              ],
              1
            ),
            _vm.areArraysIndexedEqual
              ? _c("div", { staticClass: "column management" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.setupStatusOpen,
                          expression: "setupStatusOpen",
                        },
                      ],
                      staticClass: "grid",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "cell",
                          class: {
                            highlighted: _vm.isPushedToPendingAfterLive(
                              _vm.setupStatus[index].lastCalibrationPointAdded,
                              _vm.setupStatus[index].lastPushToLive
                            ),
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getFormattedDate(
                                  _vm.setupStatus[index]
                                    .lastCalibrationPointAdded
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "cell",
                          class: _vm.getDateSuccessClass(
                            _vm.setupStatus[index].lastPushToLive
                          ),
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getFormattedDate(
                                  _vm.setupStatus[index].lastPushToLive
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "cell" }, [
                        _vm._v(
                          _vm._s(
                            _vm.getDaysSinceLastLivePush(
                              _vm.setupStatus[index].lastPushToLive
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }