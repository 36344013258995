import store from '@/store';
import router from '@/router';
import axios, { AxiosResponse } from 'axios';

export default class ApiHelper {
    static getToken (): Promise<string | null> {
        return store.dispatch('auth/getToken');
    }

    static async get (url: string): Promise<AxiosResponse<unknown>> {
        const token: string = await ApiHelper.getToken();
        return axios.get(`${process.env.VUE_APP_API_ENDPOINT}/api/${url}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
    }

    static async post (url: string, payload: any, includePortfolioId = true): Promise<AxiosResponse<unknown>> {
        if (store.state.selectedScheme == null) {
            router.push('/');
        } else {
            const token: any = await ApiHelper.getToken();
            payload['clientId'] = store.state.selectedScheme.clientEntityID;

            if (includePortfolioId) {
                // add the portfolio id (for now it will be pendingPortfolioId) *sending null breaks db, so 0 is a workaround*
                payload['portfolioId'] = store.state.selectedScheme.pendingPortfolioId ?? 0;
            };

            return axios.post(
                `${process.env.VUE_APP_API_ENDPOINT}/api/${url}`,
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        }
    }

    static async postExportNavAndPerformanceToCsv (
        url: string,
        payload: any
    ): Promise<AxiosResponse<string>> {
        if (store.state.selectedScheme == null) {
            router.push('/');
        } else {
            const token: any = await ApiHelper.getToken();
            payload['clientId'] = store.state.selectedScheme.clientEntityID;
            payload['currency'] =  store.state.selectedScheme.defaultCurrency;
            payload['startDate'] = null;
            payload['endDate'] = null;
            // add the portfolio id (for now it will be pendingPortfolioId) *sending null breaks db, so 0 is a workaround*
            payload['portfolioId'] = store.state.selectedScheme.pendingPortfolioId ?? 0;
            return axios.post(
                `${process.env.VUE_APP_API_ENDPOINT}/api/${url}`,
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        }
    }

    static async postLivePortfolioId (
        url: string,
        payload: any
    ): Promise<AxiosResponse<unknown>> {
        if (store.state.selectedScheme == null) {
            router.push('/');
        } else {
            const token: any = await ApiHelper.getToken();
            payload['clientId'] = store.state.selectedScheme.clientEntityID;
            // add the portfolio id (for now it will be pendingPortfolioId) *sending null breaks db, so 0 is a workaround*
            payload['portfolioId'] = store.state.selectedScheme.livePortfolioId ?? 0;
            return axios.post(
                `${process.env.VUE_APP_API_ENDPOINT}/api/${url}`,
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        }
    }

    static async postFile (
        url: string,
        payload: any
    ): Promise<AxiosResponse<unknown>> {
        if (store.state.selectedScheme == null) {
            router.push('/');
        } else {
            const token: any = await ApiHelper.getToken();
            // TODO: clientId is passed in headers as not able to set property on 'Content-Type': "'multipart/form-data", so this needs verifying on db
            return axios.post(
                `${process.env.VUE_APP_API_ENDPOINT}/api/${url}`,
                payload,
                {
                    headers: {
                        'content-type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                        clientId: store.state.selectedScheme.clientEntityID,
                        // add the portfolio id (for now it will be pendingPortfolioId) *sending null should not be an issue on bulk upload page*
                        portfolioId: store.state.selectedScheme.pendingPortfolioId.toString(10)
                    },
                }
            );
        }
    }

    static async postNoClientId (
        url: string,
        payload: any
    ): Promise<AxiosResponse<unknown>> {
        if (store.state.selectedScheme == null) {
            router.push('/');
        } else {
            const token: any = await ApiHelper.getToken();
            return axios.post(
                `${process.env.VUE_APP_API_ENDPOINT}/api/${url}`,
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        }
    }
}
