import { ITickerAvailabilityDto } from '@/interfaces/dto/ITickerAvailabilityDto';
import ApiHelper from './apiHelper';

export default class TickerDataAvailabilityApi {
    static async getTickers (): Promise<Array<ITickerAvailabilityDto>> {
        try {
            const response = await ApiHelper.get('GetAvailableTickers');
            return response.data as Array<ITickerAvailabilityDto>;
        } catch (error) {
            //error
        }
    }
}
