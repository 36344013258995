var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tooltip,
              expression: "tooltip",
            },
          ],
          staticClass: "api-btn",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              _vm.isVisible = true
            },
          },
        },
        [
          _vm._v(" Monitor Pending & Responsible Investment APIs status "),
          _c("font-awesome-icon", {
            staticClass: "fa-fw",
            class: _vm.classObject,
            attrs: { icon: ["fal", _vm.icon], spin: _vm.isPending },
          }),
        ],
        1
      ),
      _c(
        "SlideInModal",
        {
          attrs: { isVisible: _vm.isVisible },
          on: {
            close: function ($event) {
              _vm.isVisible = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "apis" },
            [
              _c("HealthCheckApiCheckIcon", {
                attrs: {
                  loadingStatus: _vm.loadingStatusDataStreams,
                  isSuccessful: _vm.isSuccessfulDataStreams,
                  name: "GetDataStreams",
                  description: "This API is used to load the selected scheme.",
                },
              }),
              _c("HealthCheckApiCheckIcon", {
                attrs: {
                  loadingStatus: _vm.loadingStatusSnapshotTable,
                  isSuccessful: _vm.isSuccessfulSnapshotTable,
                  name: "GetSnapshotTable",
                  description:
                    "This API is used to load the snapshot table for the selected scheme.",
                },
              }),
              _c("HealthCheckApiCheckIcon", {
                attrs: {
                  loadingStatus: _vm.loadingStatusRiAnalytics,
                  isSuccessful: _vm.isSuccessfulRiAnalytics,
                  name: "RiAnalytics",
                  description:
                    "This API is used to load the Responsible Investment analytics for the selected scheme.",
                },
              }),
              _c("HealthCheckApiCheckIcon", {
                attrs: {
                  loadingStatus: _vm.loadingStatusRiAnalyticsLcpAlternatives,
                  isSuccessful: _vm.isSuccessfulRiAnalyticsLcpAlternatives,
                  name: "RiAnalyticsLcpAlternatives",
                  description:
                    "This API is used to load the Responsible Investment LCP Alternatives for the selected scheme.",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }