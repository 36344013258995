import ExternalAuthenticationService from '@/auth/externalAuthenticationService';

export default class Config {
  static siteName = 'Monitor';

  static siteAuth = 'lcpmonitor';

  static AuthService = ExternalAuthenticationService;
}

