





























































































import { Component, Vue } from 'vue-property-decorator';

interface IRouteLink {
    name: string;
    routeName: string;
    routeTo: string;
}

const managerDataLinks: Array<IRouteLink> = [
    {
        name: 'Market values',
        routeName: 'MarketValueCashflow',
        routeTo: '/market-value-cashflow',
    },
    {
        name: 'Exposure values',
        routeName: 'ExposureValues',
        routeTo: '/exposure-values',
    },
    {
        name: 'Performance',
        routeName: 'Performance',
        routeTo: '/performance',
    },
    {
        name: 'Benchmark performance',
        routeName: 'BenchmarkPerformance',
        routeTo: '/benchmark-performance',
    },
    {
        name: 'Bulk operations',
        routeName: 'BulkOperations',
        routeTo: '/bulk-operations',
    },
];


const managerSettingsLinks = [
    {
        name: 'Manager settings',
        routeName: 'ManagerSettings',
        routeTo: '/manager-settings',
    },
    {
        name: 'Strategic allocations',
        routeName: 'StrategicAllocations',
        routeTo: '/strategic-allocations',
    }
];

const generalSettingsLinks : Array<IRouteLink> = [
    {
        name: 'Alternative performance settings',
        routeName: 'AlternativeCalculationsSettings',
        routeTo: '/alternative-calculations-settings',
    },
    {
        name: 'Client setup',
        routeName: 'ClientSetup',
        routeTo: '/client-setup',
    }
];

Object.freeze(managerDataLinks);
Object.freeze(managerSettingsLinks);
Object.freeze(generalSettingsLinks);

@Component
export default class NavBar extends Vue {
    managerDataLinks = managerDataLinks;

    managerSettingsLinks = managerSettingsLinks;

    generalSettingsLinks = generalSettingsLinks;

    showExternalSiteLinks = false;

    toggleExternalSiteLinks (): void {
        this.showExternalSiteLinks = !this.showExternalSiteLinks;
    }

    get showGeneralSettings () : boolean {
        return generalSettingsLinks.length > 0;
    }

    public logout (): void {
        this.$store.dispatch('auth/logout').then(() => {
            this.$store.dispatch('auth/getToken');
        });
    }

    public version = process.env.VUE_APP_VERSION_NUMBER ?? -1;

    public isAltCalcsFeatureLive: boolean = process.env.VUE_APP_FEATURE_ALTERNATIVE_CALCS === 'true';

    get externalSiteLinks (): Array<IRouteLink> {
        return [
            {
                name: 'Guidance and materials',
                routeName: 'GuidanceAndMaterials',
                routeTo: 'https://lcponline.sharepoint.com/teams/DBPensionSchemes/SitePages/LCP-Monitor---guidance-and-materials-hub.aspx',
            },
            {
                name: 'Authorise',
                routeName: 'Authorise',
                routeTo: process.env.VUE_APP_AUTHORISE_URL,
            },
            {
                name: 'Monitor Pending',
                routeName: 'MonitorPending',
                routeTo: process.env.VUE_APP_PENDING_URL,
            },
            {
                name: 'Monitor Live',
                routeName: 'MonitorLive',
                routeTo: process.env.VUE_APP_MONITOR_URL,
            },
            {
                name: 'Investment Research',
                routeName: 'investmentResearch',
                routeTo: process.env.VUE_APP_INVESTMENT_RESEARCH_URL,
            },
        ];
    }
}

