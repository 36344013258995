export const isValidRedirectUrl = (url: string, origin: string = window.location.origin): boolean => {
    try {
        const parsedUrl = new URL(url, origin);

        // Return true if the URL has the same origin, otherwise false
        return parsedUrl.origin === origin;
    } catch (error) {
        // If URL parsing fails, it's not a valid URL
        return false;
    }
};