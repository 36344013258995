










import { Component, Vue, Prop } from 'vue-property-decorator';
@Component

export default class SlideInModal extends Vue {
    @Prop({type: Boolean, required: true}) isVisible!: boolean;

    closeModal ():void {
        this.$emit('close');
    }
}
