import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import Client from '@/models/Client';
import modules from './modules';
import { ITickerAvailabilityDto } from '@/interfaces/dto/ITickerAvailabilityDto';
import TickerDataAvailabilityApi from '@/api/tickerDataAvailabilityApi';
import ManagerResearchApi from '@/api/managerResearchApi';
import { IManagerSettingsBreadcrumb } from '@/interfaces/IManagerSettingsBreadcrumb';
import { IFundLatestGradeDto } from '@/interfaces/dto/IFundLatestGradeDto';

Vue.use(Vuex);

interface RootState {
    clients: Array<Client>;
    selectedScheme: null | Client;
    clientEntityNameDisplayName: string;
    unsavedData: boolean;
    showUnSavedMessage: boolean;
    availableTickers: ReadonlyArray<ITickerAvailabilityDto>;
    fundsWithLatestGrade: ReadonlyArray<IFundLatestGradeDto>;
    networkErrorMessages: string[];
    networkValidationMessage: string;
    breadcrumb: IManagerSettingsBreadcrumb[];
}

const store: StoreOptions<RootState> = {
    state: {
        clients: [],
        selectedScheme: null,
        clientEntityNameDisplayName: '',
        unsavedData: false,
        showUnSavedMessage: false,
        availableTickers: [],
        fundsWithLatestGrade: [],
        networkErrorMessages: [],
        networkValidationMessage: '',
        breadcrumb: [],

    },
    mutations: {
        updateClients (state, clients: Client[]): void {
            Vue.set(state, 'clients', clients);
        },
        UPDATE_SELECTED_SCHEME (state, client: Client): void {
            Vue.set(state, 'selectedScheme', client);
        },
        UPDATE_CLIENT_DISPLAY_NAME (state, clientEntityNameDisplayName: string): void {
            Vue.set(state, 'clientEntityNameDisplayName', clientEntityNameDisplayName);
        },
        updateUnsavedData (state, isUnsavedData: boolean): void {
            Vue.set(state, 'unsavedData', isUnsavedData);
        },
        updateShowUnSavedMessage (state, showMessage: boolean): void {
            Vue.set(state, 'showUnSavedMessage', showMessage);
        },
        SET_AVAILABLE_TICKERS (state, tickers: Array<ITickerAvailabilityDto>): void {
            state.availableTickers = Object.freeze(tickers);
        },
        SET_FUNDS_WITH_LATEST_GRADE (state, fundsWithLatestGrade: Array<IFundLatestGradeDto>): void {
            state.fundsWithLatestGrade = Object.freeze(fundsWithLatestGrade);
        },
        PUSH_NETWORK_ERROR_MESSAGE (state, networkErrorMessage: string): void {
            state.networkErrorMessages.push(networkErrorMessage);
        },
        PUSH_NETWORK_VALIDATION_MESSAGE (state, networkValidationMessage: string): void {
            Vue.set(state, 'networkValidationMessage', networkValidationMessage);
        },
        CLEAR_API_MESSAGES (state): void {
            state.networkErrorMessages = [];
            state.networkValidationMessage = '';
        },
        UPDATE_BREADCRUMB (state, breadcrumb: IManagerSettingsBreadcrumb[]): void {
            state.breadcrumb = breadcrumb;
        },

    },
    actions: {
        updateSelectedScheme ({ commit }, client: Client): void {
            commit('UPDATE_SELECTED_SCHEME', client);
        },
        updateClientDisplayName ({ commit }, clientEntityNameDisplayName: string): void {
            commit('UPDATE_CLIENT_DISPLAY_NAME', clientEntityNameDisplayName);
        },
        getAvailableTickers ({ commit }) {
            TickerDataAvailabilityApi.getTickers().then((responseData) => {
                commit('SET_AVAILABLE_TICKERS', responseData);
            }).catch((error) => {
                // TODO: handle error
                // console.error(error);
            }).finally(() => {
                // console.log('finally');
            });
        },
        getFundsWithLatestGrade ({ commit }) {
            ManagerResearchApi.getFunds().then((response) => {
                commit('SET_FUNDS_WITH_LATEST_GRADE', response.data);
            }).catch((error) => {
                commit('SET_FUNDS_WITH_LATEST_GRADE', []);
                commit('PUSH_NETWORK_ERROR_MESSAGE', 'There was an issue fetching funds from the Investment Research database');
            });
        },
        pushNetworkErrorMessage ({ commit }, networkErrorMessage: string) {
            commit('PUSH_NETWORK_ERROR_MESSAGE', networkErrorMessage);
        },
        pushNetworkValidationMessage ({ commit }, networkValidationMessage: string) {
            commit('PUSH_NETWORK_VALIDATION_MESSAGE', networkValidationMessage);
        },
        clearAPIMessages ({ commit }) {
            commit('CLEAR_API_MESSAGES');
        },
        updateBreadcrumb ({ commit }, items: IManagerSettingsBreadcrumb[]): void {
            commit('UPDATE_BREADCRUMB', items);
        },

    },
    getters: {
        getNetworkErrorMessages: (state): string[] => {
            return state.networkErrorMessages;
        },
        getNetworkValidationMessage: (state): string => {
            return state.networkValidationMessage;
        },
        getCurrencySymbol: (state): string => {
            // https://www.xe.com/currency/
            const currency = state.selectedScheme.defaultCurrency || null;
            if (!currency) {
                return '';
            }
            switch (currency.toLowerCase()) {
            case 'gbp':
                return '£';
            case 'usd':
                return '$';
            case 'bwp':
                return 'BWP';
            case 'xdr':
                return 'SDR';
            case 'zar':
                return 'R'; // South African Rand
            default:
                return currency;
            }
        }
    },

    modules: modules,
};
export default new Vuex.Store<RootState>(store);
