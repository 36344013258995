var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", { staticClass: "modal", class: { "is-active": _vm.showError } }, [
      _c("div", { staticClass: "modal-background" }),
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "box" }, [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("font-awesome-icon", {
                staticClass: "error-icon",
                attrs: { icon: ["fa", "exclamation-triangle"] },
              }),
              _c("h1", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.errorText)),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom" },
            [
              _vm._l(_vm.networkErrorMessages, function (error) {
                return _c("p", { key: error }, [_vm._v(_vm._s(error))])
              }),
              _vm.isNetworkValidationError
                ? _c("pre", [_vm._v(_vm._s(_vm.networkValidationErrorMessage))])
                : _vm._e(),
              _c(
                "button",
                { staticClass: "btn btn--default", on: { click: _vm.close } },
                [_vm._v("Close")]
              ),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }