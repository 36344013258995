var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar" }, [
    _c(
      "div",
      { staticClass: "logo" },
      [_c("router-link", { attrs: { to: "/" } }, [_vm._v("LCP Monitor Pro")])],
      1
    ),
    _c(
      "div",
      { staticClass: "nav-items-wrapper" },
      [
        _c("div", [
          _c("div", { staticClass: "nav-item cursor-default" }, [
            _c(
              "span",
              { staticClass: "nav-icon" },
              [_c("font-awesome-icon", { attrs: { icon: ["fal", "cogs"] } })],
              1
            ),
            _c("span", [_vm._v("Manager setup")]),
          ]),
          _c(
            "div",
            { staticClass: "sub-nav" },
            _vm._l(
              _vm.managerSettingsLinks,
              function (managerSettingsLink, index) {
                return _c(
                  "router-link",
                  { key: index, attrs: { to: managerSettingsLink.routeTo } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "nav-item",
                        class: {
                          "active-item":
                            _vm.$route.name === managerSettingsLink.routeName,
                        },
                      },
                      [_vm._v(" " + _vm._s(managerSettingsLink.name) + " ")]
                    ),
                  ]
                )
              }
            ),
            1
          ),
        ]),
        _c("div", [
          _c("div", { staticClass: "nav-item cursor-default" }, [
            _c(
              "span",
              { staticClass: "nav-icon" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "analytics"] },
                }),
              ],
              1
            ),
            _c("span", [_vm._v("Manager data")]),
          ]),
          _c(
            "div",
            { staticClass: "sub-nav" },
            _vm._l(_vm.managerDataLinks, function (managerDataLink, index) {
              return _c(
                "router-link",
                { key: index, attrs: { to: managerDataLink.routeTo } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "nav-item",
                      class: {
                        "active-item":
                          _vm.$route.name === managerDataLink.routeName,
                      },
                    },
                    [_vm._v(" " + _vm._s(managerDataLink.name) + " ")]
                  ),
                ]
              )
            }),
            1
          ),
        ]),
        _c("router-link", { attrs: { to: "/health-check" } }, [
          _c(
            "div",
            {
              staticClass: "nav-item",
              class: { "active-item": _vm.$route.name === "HealthCheck" },
            },
            [
              _c(
                "span",
                { staticClass: "nav-icon" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "heartbeat"] },
                  }),
                ],
                1
              ),
              _c("span", [_vm._v("Health check")]),
            ]
          ),
        ]),
        _c("div", [
          _c("div", { staticClass: "nav-item cursor-default" }, [
            _c(
              "span",
              { staticClass: "nav-icon" },
              [_c("font-awesome-icon", { attrs: { icon: ["far", "wrench"] } })],
              1
            ),
            _c("span", [_vm._v("General settings")]),
          ]),
          _c(
            "div",
            { staticClass: "sub-nav" },
            _vm._l(
              _vm.generalSettingsLinks,
              function (generalSettingsLink, index) {
                return _c(
                  "router-link",
                  { key: index, attrs: { to: generalSettingsLink.routeTo } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "nav-item",
                        class: {
                          "active-item":
                            _vm.$route.name === generalSettingsLink.routeName,
                        },
                      },
                      [_vm._v(" " + _vm._s(generalSettingsLink.name) + " ")]
                    ),
                  ]
                )
              }
            ),
            1
          ),
        ]),
        _c("router-link", { attrs: { to: "/release-audit" } }, [
          _c(
            "div",
            {
              staticClass: "nav-item",
              class: { "active-item": _vm.$route.name === "ReleaseAudit" },
            },
            [
              _c(
                "span",
                { staticClass: "nav-icon" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "history"] },
                  }),
                ],
                1
              ),
              _c("span", [_vm._v("Release audit")]),
            ]
          ),
        ]),
        _c("router-link", { attrs: { to: "/fund-recommendation-report" } }, [
          _c(
            "div",
            {
              staticClass: "nav-item",
              class: {
                "active-item": _vm.$route.name === "FundRecommendationReport",
              },
            },
            [
              _c(
                "span",
                { staticClass: "nav-icon" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "badge-check"] },
                  }),
                ],
                1
              ),
              _c("span", [_vm._v("Fund recommendation form")]),
            ]
          ),
        ]),
        _vm._m(0),
        _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass: "nav-item",
                on: {
                  click: function ($event) {
                    return _vm.toggleExternalSiteLinks()
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "nav-icon" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fal", "link"] },
                    }),
                  ],
                  1
                ),
                _c("span", [_vm._v("External links")]),
                _c("font-awesome-icon", {
                  staticClass: "dropdown-icon",
                  class: { flipped: _vm.showExternalSiteLinks },
                  attrs: { icon: ["far", "chevron-down"] },
                }),
              ],
              1
            ),
            _c(
              "transition-group",
              { attrs: { name: "fade", mode: "out-in" } },
              [
                _vm.showExternalSiteLinks
                  ? _c(
                      "div",
                      { key: "sub-nav", staticClass: "sub-nav" },
                      _vm._l(_vm.externalSiteLinks, function (link) {
                        return _c(
                          "a",
                          {
                            key: link.routeName,
                            attrs: { target: "_blank", href: link.routeTo },
                          },
                          [
                            _c("div", { staticClass: "sub-nav-item" }, [
                              _c("span", [_vm._v(_vm._s(link.name))]),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "nav-item", on: { click: _vm.logout } }, [
          _c(
            "span",
            { staticClass: "nav-icon" },
            [_c("font-awesome-icon", { attrs: { icon: ["fal", "sign-out"] } })],
            1
          ),
          _c("span", [_vm._v("Log out")]),
        ]),
        _c("div", { staticClass: "nav-item cursor-default" }, [
          _c("span", [_vm._v("Site version: " + _vm._s(_vm.version))]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav-item cursor-default" }, [
      _c("div", { staticClass: "nav-divider" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }