var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _c("div", { staticClass: "left" }, [
      _vm._m(0),
      _c("div", { staticClass: "primary-button-lg login-action" }, [
        _c(
          "div",
          { staticClass: "btn-content", on: { click: _vm.login } },
          [
            _vm._v(" Log in "),
            _c("font-awesome-icon", {
              staticClass: "icon",
              attrs: { icon: ["fad", "user-friends"] },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "right" }, [_c("Parallelograms"), _vm._m(1)], 1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("h1", [_vm._v("LCP Monitor Pro")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "innerText" }, [
      _c("div", { staticClass: "heading" }, [_vm._v("One tool,")]),
      _c("div", { staticClass: "heading" }, [
        _vm._v("All of your investment monitoring needs"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }