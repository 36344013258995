var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loading-overlay flex flex--row-center" }, [
    _c("div", { staticClass: "loading" }, [
      _c(
        "span",
        { staticClass: "spinner-icon" },
        [
          _c("font-awesome-icon", {
            attrs: { icon: ["fad", "spinner-third"], spin: "" },
          }),
        ],
        1
      ),
      _vm._v(" Loading... "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }