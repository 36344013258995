
















































import { Component, Vue } from 'vue-property-decorator';
import Parallelograms from '@/components/Parallelograms.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import ClientLoginContainer from '@/components/ClientLoginContainer.vue';
import Client from '@/models/Client';
import { IUserManagementPageData } from '@/interfaces/user/IUserManagementPageData';
import UserApi from '@/api/userApi';
import VTooltip from 'v-tooltip';

Vue.use(VTooltip);
@Component({
    components: {
        Parallelograms,
        LoadingOverlay,
        ClientLoginContainer
    },
})
export default class ClientView extends Vue {
    public isLoading = true;

    public managementTableOpen = false;

    public managementData: Array<IUserManagementPageData> = [];

    public clientSearchText = '';

    public updateSelectedScheme (client: Client): void {
        this.$store.dispatch('updateSelectedScheme', client);
        this.$store.dispatch('updateClientDisplayName', client.clientEntityNameDisplayName);
        this.$store.dispatch('updateBreadcrumb', [{id: -1, name:client.clientEntityNameDisplayName}]);

        this.$router.push('/manager-settings');
    }

    public get clients (): Array<Client> {
        return this.$store.state.clients;
    }

    public get clientsLength (): number {
        return this.clients.length;
    }

    public get filteredClients (): Array<Client> {
        return this.clients.filter((client) => client.clientEntityNameDisplayName.toLowerCase().includes(this.clientSearchText.toLowerCase()));
    }

    public get filteredClientsLength (): number {
        return this.filteredClients.length;
    }

    public get filteredManagementData (): Array<IUserManagementPageData> {
        return this.managementData.filter((client) => client.name.toLowerCase().includes(this.clientSearchText.toLowerCase()));
    }

    handleKeyup ():void {
        if (this.filteredClients.length === 1) {
            this.selectClientById(this.filteredClients[0].clientEntityID);
        }
    }

    selectClientById (clientId: string):void {
        const result = this.clients.find((client) => client.clientEntityID === clientId);
        if (!result) return;
        this.updateSelectedScheme(result);
    }

    focusSearch (): void {
        if (!this.$refs.searchInput) return;
        (this.$refs.searchInput as HTMLInputElement).focus();
    }

    async fetchManagementData (): Promise<void> {
        try {
            const managementData = await UserApi.getUserManagementPageData();
            this.managementData = managementData;
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Error fetching user management data');
        }
    }

    async fetchUserSchemes (): Promise<void> {
        try {
            const userSchemes = await UserApi.getUserSchemes();
            this.$store.commit('updateClients', userSchemes);
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Error fetching user schemes');
        } finally {
            this.isLoading = false;
            this.$nextTick(() => {
                this.focusSearch();
            });
        }
    }

    created (): void {
        this.fetchManagementData();
        this.fetchUserSchemes();
    }

    public toggleManagementTable (): void {
        this.managementTableOpen = !this.managementTableOpen;
    }
}

