var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide", appear: "" } }, [
    _vm.isVisible
      ? _c(
          "div",
          { staticClass: "slide-in-component" },
          [
            _c(
              "button",
              {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: { click: _vm.closeModal },
              },
              [_vm._v("×")]
            ),
            _vm._t("default"),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }