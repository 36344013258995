var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-bar" },
    [
      _c("ManagerSettingsBreadcrumb", {
        attrs: { breadcrumb: _vm.$store.state.breadcrumb },
      }),
      _vm.isHealthCheckRoute
        ? _c("HealthCheckApiCheckIconContainer", {
            attrs: { selectedSchemeCurrency: _vm.selectedSchemeCurrency },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }