










import { Component, Vue, Prop } from 'vue-property-decorator';
import { IManagerSettingsBreadcrumb } from '@/interfaces/IManagerSettingsBreadcrumb';

@Component({
    components: {},
})
export default class ManagerSettingsBreadcrumb extends Vue {
  @Prop({type: Array, required: true}) breadcrumb: IManagerSettingsBreadcrumb[];
}
