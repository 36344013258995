import { IUserManagementPageData } from '@/interfaces/user/IUserManagementPageData';
import ApiHelper from './apiHelper';
import Client from '@/models/Client';

export default class UserApi {
    static async getUserSchemes (): Promise<Array<Client>> {
        return (await ApiHelper.get('GetUserSchemes')).data as Array<Client>;
    }

    static async getUserManagementPageData (): Promise<Array<IUserManagementPageData>> {
        return (await ApiHelper.get('GetUserManagementPageData')).data as Array<IUserManagementPageData>;
    }
}