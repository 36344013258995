














































import { IUserManagementPageData } from '@/interfaces/user/IUserManagementPageData';
import dayjs from 'dayjs';
import {FORMAT_DATE_UI} from '@/constants';
import Client from '@/models/Client';
import { Component, Vue, Prop } from 'vue-property-decorator';
import VTooltip from 'v-tooltip';

Vue.use(VTooltip);

const HISTORIC_OFFSET_IN_MONTHS = 3;

@Component({ inheritAttrs: false })
export default class ClientLoginContainer extends Vue {
    @Prop({ required: true, type: Array }) setupStatus!: Array<IUserManagementPageData>;

    @Prop({ required: true, type: Array }) filteredClients!:Array<Client>;

    @Prop({ required: true, type: Boolean }) setupStatusOpen!:boolean;

    public get areArraysIndexedEqual (): boolean {
        if (this.setupStatus.length !== this.filteredClients.length) return false;

        for (let i = 0; i < this.setupStatus.length; i++) {
            if (this.setupStatus[i].clientId !== this.filteredClients[i].clientEntityID) return false;
        }

        return true;
    }

    handleClick (clientId: string) :void{
        this.$emit('select-client-by-id', clientId);
    }

    getFormattedDate (dateString: string): string {
        if (!dateString) return '-';

        return dayjs(dateString).format(FORMAT_DATE_UI);
    }

    getDateSuccessClass (dateString: string): string {
        if (!dateString) return '';

        return dayjs(dateString).isBefore(dayjs().subtract(HISTORIC_OFFSET_IN_MONTHS, 'months')) ? 'warn' : 'success';
    }

    isPushedToPendingAfterLive (lastCalibrationPointAdded:string, lastPushToLive: string): boolean {
        if (!lastCalibrationPointAdded) return false;

        return !lastPushToLive || dayjs(lastPushToLive).isBefore(dayjs(lastCalibrationPointAdded));
    }

    getDaysSinceLastLivePush (lastPushToLive: string): number | '-' {
        if (!lastPushToLive) return '-';

        return dayjs().diff(dayjs(lastPushToLive), 'days');
    }
}
