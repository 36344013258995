






















import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import TopBar from '@/components/TopBar.vue';
import Login from '@/views/Login.vue';
import NetworkError from '@/components/NetworkError.vue';
import { isValidRedirectUrl } from '@/utils/isValidRedirectUrl';

@Component({
    components: {
        NavBar,
        Login,
        TopBar,
        NetworkError
    },
})
export default class App extends Vue {
    async mounted (): Promise<void> {
        await this.$store
            .dispatch('auth/getToken')
            .then((result) => {
                if (!result) {
                    if (this.$route.query?.signIn || this.$route.query?.signin) {
                        this.$router.push(this.$route.path);
                        this.login();
                    }
                }
            })
            .catch(() => {
                // TODO: how do we want to handle this?
            });
        if (this.$route.query?.signIn || this.$route.query?.signin) {
            this.$router.push(this.$route.path);
        }
    }

    async login (): Promise<void> {
        try {
            await this.$store.dispatch('auth/login');
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Login failed. Please try again.');
        }
        const url = this.$route.query.url as string;

        if (url && isValidRedirectUrl(url)) {
            window.location.href = url;
        } else {
            window.location.href = '';
        }


        // This code block might be redundant. We are not sure if it is used.
        this.$nextTick(() => {
            this.$store.dispatch('auth/getToken');
        });
    }

    get isDashboardDisplay (): boolean {
        const { name } = this.$route;
        if (name === 'Login') return false;
        if (name === 'Client') return false;
        return true;
    }

    get currentRouteName (): string {
        return this.$route.name;
    }

    get loggedIn (): boolean {
        return this.$store.getters['auth/loggedIn'];
    }
}
