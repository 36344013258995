























import { Component, Vue } from 'vue-property-decorator';
import { isValidRedirectUrl } from '@/utils/isValidRedirectUrl';

import Parallelograms from '@/components/Parallelograms.vue';

@Component({
    components: {
        Parallelograms,
    },
})
export default class Login extends Vue {

    public async login (): Promise<void> {
        try {
            await this.$store.dispatch('auth/login');
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Login failed. Please try again.');
        }
        const url = this.$route.query.url as string;
        if (url && isValidRedirectUrl(url)) {
            window.location.href = url;
        } else {
            window.location.href = '';
        }

        // This code block might be redundant. We are not sure if it is used.
        this.$nextTick(() => {
            this.$store.dispatch('auth/getToken');
        });
    }
}
