import { IGetSnapshotTableRequest } from '@/interfaces/dto/IGetSnapshotTableRequest';
import ApiHelper from './apiHelper';
import { IGetRiAnalyticsRequest } from '@/interfaces/dto/IGetRiAnalyticsRequest';
import { IGetRiAnalyticsLcpAlternativesRequest } from '@/interfaces/dto/IGetRiAnalyticsLcpAlternativesRequest';
import { IGetDataStreamsRequestDto } from '@/interfaces/dto/IGetDataStreamsRequestDto';

export default class APIChecker {
    static async getDataStreams (payload: IGetDataStreamsRequestDto): Promise<{status: number, data: any}> {

        const response = await ApiHelper.post('GetDataStreamsV3', payload, false);
        const { status, data } = response;
        return { status, data };
    }

    static async getSnapshotTable (payload: IGetSnapshotTableRequest): Promise<number> {
        const status = (await ApiHelper.post('GetSnapshotTableV3', payload, false)).status;
        return status;
    }

    static async getRiAnalytics (payload: IGetRiAnalyticsRequest): Promise<{status: number, data: any}> {
        const response = await ApiHelper.post('RiAnalyticsV3', payload, false);
        const { status, data } = response;
        return { status, data };
    }

    static async getRiAnalyticsLcpAlternatives (payload: IGetRiAnalyticsLcpAlternativesRequest): Promise<number> {
        const status = (await ApiHelper.post('RiAnalyticsLcpAlternativesV3', payload, false)).status;
        return status;
    }
}