





















import { Vue,  Component } from 'vue-property-decorator';

@Component
export default class NetworkError extends Vue {


    get networkValidationErrorMessage (): string {
        return this.$store.getters.getNetworkValidationMessage;
    }

    get isNetworkGenericError (): boolean {
        return this.networkErrorMessages.length ? true : false;
    }

    get isNetworkValidationError (): boolean {
        return this.networkValidationErrorMessage.length ? true : false;
    }

    get errorText (): string {
        return this.isNetworkGenericError ? 'Network Error' : 'Validation Error';
    }


    get showError (): boolean {
        return this.isNetworkGenericError || this.isNetworkValidationError;
    }

    get networkErrorMessages (): string[] {
        return this.$store.getters.getNetworkErrorMessages;
    }


    close (): void {
        this.$store.dispatch('clearAPIMessages' );
    }

};
