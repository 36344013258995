// convert string to words
function toWords (input: string) {
    const regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;
    return input.match(regex);
}

function toCamelCase (inputArray: RegExpMatchArray) {
    let result = '';

    for (let i = 0, len = inputArray.length; i < len; i += 1) {
        const currentStr = inputArray[i];

        let tempStr = currentStr.toLowerCase();

        if (i !== 0) {
            tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
        }

        result += tempStr;
    }

    return result;
}

export default function toCamelCaseString (input: string) {
    const words = toWords(input);
    if (!words) return input;
    return toCamelCase(words);
}
