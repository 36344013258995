import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store/index';
import ClientView from '@/views/Client.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Client',
        component: ClientView,
        beforeEnter: (to, from, next) => {
            if (store.state.unsavedData) {
                store.commit('updateShowUnSavedMessage', true);
            } else {
                next();
            }
        },
    },
    {
        path: '/manager-settings',
        name: 'ManagerSettings',
        beforeEnter: (to, from, next) => {
            if (store.state.unsavedData) {
                store.commit('updateShowUnSavedMessage', true);
            } else {
                next();
            }
        },
        component: () => import('../views/ManagerSettings.vue'),
    },
    {
        path: '/strategic-allocations',
        name: 'StrategicAllocations',
        beforeEnter: (to, from, next) => {
            if (store.state.unsavedData) {
                store.commit('updateShowUnSavedMessage', true);
            } else {
                next();
            }
        },
        component: () => import('../views/StrategicAllocations.vue'),
    },
    {
        path: '/market-value-cashflow',
        name: 'MarketValueCashflow',
        beforeEnter: (to, from, next) => {
            if (store.state.unsavedData) {
                store.commit('updateShowUnSavedMessage', true);
            } else {
                next();
            }
        },
        component: () => import('../views/MarketValueAndCashflow.vue'),
    },
    {
        path: '/exposure-values',
        name: 'ExposureValues',
        beforeEnter: (to, from, next) => {
            if (store.state.unsavedData) {
                store.commit('updateShowUnSavedMessage', true);
            } else {
                next();
            }
        },
        component: () => import('../views/MarketValueAndCashflow.vue'),
    },
    {
        path: '/performance',
        name: 'Performance',
        props: { performanceType: 'managerPerformance' },

        beforeEnter: (to, from, next) => {
            if (store.state.unsavedData) {
                store.commit('updateShowUnSavedMessage', true);
            } else {
                next();
            }
        },
        component: () => import('../views/Performance.vue'),
    },
    {
        path: '/benchmark-performance',
        name: 'BenchmarkPerformance',
        props: { performanceType: 'benchmarkPerformance' },
        beforeEnter: (to, from, next) => {
            if (store.state.unsavedData) {
                store.commit('updateShowUnSavedMessage', true);
            } else {
                next();
            }
        },
        component: () => import('../views/Performance.vue'),
    },
    {
        path: '/bulk-operations',
        name: 'BulkOperations',
        beforeEnter: (to, from, next) => {
            if (store.state.unsavedData) {
                store.commit('updateShowUnSavedMessage', true);
            } else {
                next();
            }
        },
        component: () => import('../views/BulkOperations.vue'),
    },
    {
        path: '/health-check',
        name: 'HealthCheck',
        beforeEnter: (to, from, next) => {
            if (store.state.unsavedData) {
                store.commit('updateShowUnSavedMessage', true);
            } else {
                next();
            }
        },
        component: () => import('../views/HealthCheck.vue'),
    },
    {
        path: '/alternative-calculations-settings',
        name: 'AlternativeCalculationsSettings',
        beforeEnter: (to, from, next) => {
            if (store.state.unsavedData) {
                store.commit('updateShowUnSavedMessage', true);
            } else {
                next();
            }
        },
        component: () => import('../views/AlternativeCalculationsSettingsView.vue'),
    },
    {
        path: '/client-setup',
        name: 'ClientSetup',
        beforeEnter: (to, from, next) => {
            if (store.state.unsavedData) {
                store.commit('updateShowUnSavedMessage', true);
            } else {
                next();
            }
        },
        component: () => import('../views/ClientSetupView.vue'),
    },
    {
        path: '/release-audit',
        name: 'ReleaseAudit',
        beforeEnter: (to, from, next) => {
            if (store.state.unsavedData) {
                store.commit('updateShowUnSavedMessage', true);
            } else {
                next();
            }
        },
        component: () => import('../views/ReleaseAudit.vue'),
    },
    {
        path: '/fund-recommendation-report',
        name: 'FundRecommendationReport',
        beforeEnter: (to, from, next) => {
            if (store.state.unsavedData) {
                store.commit('updateShowUnSavedMessage', true);
            } else {
                next();
            }
        },
        component: () => import('../views/FundRecommendationsView.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
