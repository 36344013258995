







import { Component, Vue, Prop } from 'vue-property-decorator';
import HealthCheckApiCheckIconContainer from '@/components/MonitorManager/HealthCheckApiCheckIconContainer.vue';
import ManagerSettingsBreadcrumb from '@/components/ManagerSettingsBreadcrumb.vue';

@Component ({
    components: {
        HealthCheckApiCheckIconContainer,
        ManagerSettingsBreadcrumb
    }
})
export default class TopBar extends Vue {
    @Prop({type:String, required: false, default:'Client'}) currentRouteName?: string;

    get isHealthCheckRoute (): boolean {
        return this.currentRouteName === 'HealthCheck';
    }

    get selectedSchemeCurrency (): string {
        return this.$store.state.selectedScheme.defaultCurrency;
    }


}
