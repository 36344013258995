import {
    PublicClientApplication,
    SilentRequest,
    AuthenticationResult,
    Configuration,
    LogLevel,
    AccountInfo,
    InteractionRequiredAuthError,
    RedirectRequest,
    PopupRequest,
    EndSessionRequest,
    SsoSilentRequest,
} from '@azure/msal-browser';

/**
 * Configuration class for @azure/msal-browser:
 * https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_config_configuration_.html
 */
const MSAL_CONFIG: Configuration = {
    auth: {
        clientId: '',
        authority: '',
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                case LogLevel.Error:
                    // TODO: handle error
                    // console.error(message);
                    return;
                case LogLevel.Info:
                    // console.info(message);
                    return;
                case LogLevel.Verbose:
                    // console.debug(message);
                    return;
                case LogLevel.Warning:
                    // console.warn(message);
                    break;
                default:
                    break;
                }
            },
        },
    },
};

/**
 * AuthModule for application - handles authentication in app.
 */
export default class ExternalAuthenticationService {
    private myMSALObj: PublicClientApplication; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/classes/_src_app_publicclientapplication_.publicclientapplication.html

    private account: AccountInfo | null; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-common/modules/_src_account_accountinfo_.html

    private loginRedirectRequest: RedirectRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_redirectrequest_.html

    private loginRequest: PopupRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_popuprequest_.html

    private silentLoginRequest: SsoSilentRequest;

    constructor (config: Configuration, request: PopupRequest) {
        this.myMSALObj = new PublicClientApplication({ ...MSAL_CONFIG, ...config });
        this.account = null;

        this.loginRequest = request;

        this.loginRedirectRequest = {
            ...this.loginRequest,
            redirectStartPage: window.location.href
                .toLowerCase()
                .replace('?signin=true', ''),
        };

        this.silentLoginRequest = {
            loginHint: '',
        };
        this.loadAuthModule();
    }

    /**
     * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
     * TODO: Add account chooser code
     *
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    private getAccount (): AccountInfo | null {
        // need to call getAccount here?
        const currentAccounts = this.myMSALObj.getAllAccounts();
        if (currentAccounts === null) {
            // TODO: handle null account
            return null;
        }

        if (currentAccounts.length > 1) {
            // Add choose account code here
            return currentAccounts[0];
        }
        if (currentAccounts.length === 1) {
            return currentAccounts[0];
        }

        return null;
    }

    /**
     * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
     *
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
     */
    loadAuthModule (): Promise<AuthenticationResult | null | void> {
        return this.myMSALObj
            .handleRedirectPromise()
            .then((result) => {
                if (result?.account) this.setActiveAccount(result.account);
                return result;
            });
        // .catch(console.error); // TODO: handle error
    }

    /**
     * Calls ssoSilent to attempt silent flow. If it fails due to interaction required error, it will prompt the user to login using popup.
     * @param request
     */
    attemptSsoSilent (): Promise<void | AuthenticationResult> {
        return this.myMSALObj.ssoSilent(this.silentLoginRequest).catch((error) => {
            // TODO: handle error
            // console.error(`Silent Error: ${error}`);
            if (error instanceof InteractionRequiredAuthError) {
                this.login('loginPopup');
            }
        });
    }

    /**
     * Calls loginPopup or loginRedirect based on given signInType.
     * @param signInType
     */
    login (
        signInType: 'loginPopup' | 'loginRedirect' = 'loginRedirect'
    ): Promise<AuthenticationResult | void> {
        if (signInType === 'loginPopup') {
            return this.myMSALObj
                .loginPopup(this.loginRequest)
                .then((result) => {
                    if (result.account) {
                        this.setActiveAccount(result.account);
                    }
                });
            // .catch(console.error); // TODO: handle error
        }
        return this.myMSALObj.loginRedirect(this.loginRedirectRequest);
    }

    setActiveAccount (account: AccountInfo): void {
        this.myMSALObj.setActiveAccount(account);
        this.account = account;
    }

    /**
     * Logs out of current account.
     */
    logout (): void {
        let account: AccountInfo | undefined;
        if (this.account) {
            account = this.account;
        }
        const logOutRequest: EndSessionRequest = {
            account,
        };

        this.myMSALObj.logoutRedirect(logOutRequest);
    }

    async getToken (scopes?: Array<string>): Promise<AuthenticationResult | null> {
        await this.loadAuthModule();
        return this.getTokenRedirect(
            { scopes: scopes || this.loginRequest.scopes, forceRefresh: false },
            {
                scopes: scopes || this.loginRequest.scopes,
                redirectUri: window.location.href,
            }
        );
    }

    /**
     * Gets a token silently, or falls back to interactive popup.
     */
    private async getTokenPopup (
        silentRequest: SilentRequest,
        interactiveRequest: PopupRequest
    ): Promise<AuthenticationResult | null> {
        try {
            if (!this.myMSALObj.getActiveAccount()) {
                const account = this.getAccount();
                if (account) {
                    this.setActiveAccount(account);
                } else {
                    return null;
                }
            }
            const response: AuthenticationResult = await this.myMSALObj.acquireTokenSilent(
                silentRequest
            );
            return response;
        } catch (e) {
            // TODO: handle error
            if (e instanceof InteractionRequiredAuthError) {
                return this.myMSALObj
                    .acquireTokenPopup(interactiveRequest)
                    .catch((err) => {
                        // TODO: error
                        return null;
                    });
            }
        }

        return null;
    }

    /**
     * Gets a token silently, or falls back to interactive redirect.
     */
    private async getTokenRedirect (
        silentRequest: SilentRequest,
        interactiveRequest: RedirectRequest
    ): Promise<AuthenticationResult | null> {
        try {
            if (!this.myMSALObj.getActiveAccount()) {
                const account = this.getAccount();
                if (account) {
                    this.setActiveAccount(account);
                } else {
                    return null;
                }
            }
            const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
            return response;
        } catch (e) {
            // TODO: error
            if (e instanceof InteractionRequiredAuthError) {
                this.myMSALObj
                    .acquireTokenRedirect(interactiveRequest)
                    .catch();
            }
        }

        return null;
    }
}
